import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

axios.defaults.withCredentials = true;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isMarketing, setIsMarketing] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Check if user is currently logged in
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const authResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/auths/is-authenticated`
        );
        if (authResponse.status === 200) {
          const userResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/auths/get-user`
          );
          setUser(userResponse.data.user);
          if (
            userResponse.data.user.department ===
            "Sales and Marketing Department"
          ) {
            setIsMarketing(true);
          } else {
            setIsMarketing(false);
          }
        }
      } catch (err) {
        if (err.response?.status !== 404) {
          console.error(err); // Optionally log other errors
        }
        setUser(null);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };
    checkAuthStatus();
  }, [location.pathname]);

  const login = async (credentials) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/auths/login`,
        JSON.stringify(credentials),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      console.error("Login error:", err);
      return err;
    }
  };

  const logout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/auths/logout`);
      setUser(null); // Clear user data on logout
      return response;
    } catch (err) {
      console.error("Logout error:", err);
      return err;
    }
  };

  return (
    <AuthContext.Provider value={{ isMarketing, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
