import Swal from "sweetalert2";
import "./utils.css";

export const Toast = Swal.mixin({
  toast: true,
  position: "bottom-left",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
  customClass: {
    container: "custom-swal-toast",
  },
});



export const confirmDialog = (options) => {
  const defaultOptions = {
    icon: "warning",
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    customClass: {
      container: "custom-swal-toast",
    },
  };

  return Swal.fire({ ...defaultOptions, ...options }).then((result) => result.isConfirmed);
};