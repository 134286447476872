import './App.css';
import Signin from './pages/AuthPages/SignIn.js';
import { Routes, Route } from 'react-router-dom';
import PublicRoute from './pages/PublicRoute.js';
import AuthRoute from './pages/AuthRoute.js';
import Main from './pages/Container/main_container.js';

function App() {
  return (
    <div className="App">
         {/* Defining different Routes using Routes and Route components */}
      <Routes>
        {/* Public Route*/}
        <Route path="/" element={<PublicRoute element={<Signin />} />} />
        
        {/* Authenticated Routes */}
        <Route path="/dashboard" element={<AuthRoute element={<Main />} />} />
        <Route path="/:segment" element={<AuthRoute element={<Main />} />} /> 
        <Route path="/events/*" element={<AuthRoute element={<Main />} />} /> 
        <Route path="/events/QR/:eventID" element={<AuthRoute element={<Main />} />} /> 
        <Route path="/event/:id/:event" element={<AuthRoute element={<Main />} />} /> {/* dynamic route for events that includes event ID*/}
      </Routes>
    </div>
  );
}

export default App;
