import axios from "axios";

// Events
export const createEvent = async (formData, file) => {
  try {
    const data = new FormData();
    // Append form data
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    // Append file(wallpaper) if it exists
    if (file) {
      data.append("event_wallpaper", file);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/create`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/events/all`);
    const sortedData = response.data.sort(
      (b, a) => new Date(a.start_date) - new Date(b.start_date)
    );
    return sortedData; // Return sorted event data
  } catch (error) {
    throw new Error(
      "An error occurred while fetching events. Please try again later."
    );
  }
};

export const editEvent = async (id, updateData, file) => {
  try {
    const data = new FormData();
    // Append form data
    Object.keys(updateData).forEach((key) => {
      data.append(key, updateData[key]);
    });
    // Append file(wallpaper) if it exists
    if (file) {
      data.append("event_wallpaper", file);
    }
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/edit?id=${id}`,
      data
    );

    // return response; 
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const getEventDetails = async (eventId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/get-event-details?id=${eventId}`
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data.eventDetails[0];
    } else {
      throw new Error(
        response.data.message || "Failed to fetch event details."
      );
    }
  } catch (error) {
    console.error("Error fetching event details:", error.message);
    alert(error.message);
  }
};

export const deleteEvent = async (event_id) => {
  try {
    await axios.patch(`${process.env.REACT_APP_BACKEND_DOMAIN}/events/delete`, { event_id });
  } catch (error) {
    throw new Error(
      "An error occurred while deleting an event. Please try again later."
    );
  }
};

// Attendee
export const addAttendee = async (eventID, attendee) => {
  console.log("attendes", attendee);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/single-add?id=${eventID}`,
      attendee
    );

    return response.attendee; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const getAttendeeDetails = async (visitorId, eventId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/details?id=${eventId}&visitorId=${visitorId}`
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data.visitor_details[0];
    } else {
      throw new Error(
        response.data.message || "Failed to fetch attendee details."
      );
    }
  } catch (error) {
    console.error("Error fetching attendee details:", error.message);
    alert(error.message);
  }
};

export const fetchAttendees = async (eventID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/all?id=${eventID}`
    );
    return response.data.visitors; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const editAttendeeDets = async (visitorId, eventId, updateData) => {
  // Rename visitor_name to name
  if (updateData.visitor_name) {
    updateData.name = updateData.visitor_name;
    delete updateData.visitor_name; // Remove the old key
  }

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/edit?id=${eventId}&visitorId=${visitorId}`,
      updateData
    );
    return response; // Return the response
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const deleteAttendee = async (visitorId, eventID) => {
  try {
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/delete?id=${eventID}`,
      {
        visitorId,
      }
    );
  } catch (error) {
    throw new Error(
      "An error occurred while deleting an event. Please try again later."
    );
  }
};

// Bulk Upload for Attendee
export const uploadExcel = async (eventID, file) => {
  const formData = new FormData();
  formData.append("visitors", file);
  
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/match-fields?id=${eventID}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response;
  } catch (error) {
    console.log(error)
    throw new Error("An error occurred while uplaoding excel.");

  }
};

export const submitExcel = async (eventID, file, fieldMapping) => {
  try {
    const formData = new FormData();
    formData.append("visitors", file);
    formData.append("walk_in", true);
    // Parse fieldMapping and attach fields to formData
    Object.entries(fieldMapping).forEach(([excelHeader, dbField]) => {
      if (dbField) {
        formData.append(dbField, excelHeader);
      }
    });

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/import-visitors?id=${eventID}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response;
  } catch (error) {
    throw new Error("An error occurred while saving attendees.");
  }
};

// VIP
export const fetchVIPs = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/events-vips`
    );
    return response.data.event_vips;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching VIPs. Please try again later."
    );
  }
};

// Representative
export const deleteRep = async (eventId, visitorId, reprsId) => {
  // console.log(eventId, visitorId, reprsId);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/visitors/reprs/edit?id=${eventId}&visitorId=${visitorId}&reprsId=${reprsId}`,
      { hidden: true }
    );
    return response; // Return the response for further use
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "An unexpected error occurred."
    );
  }
};

// QR
export const sendQR = async (attendees, eventId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/qr/generate-qr?id=${eventId}`,
      attendees
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while sending email.");
  }
};

export const scanQR = async (visitorId, eventId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/qr/update-status?id=${eventId}`,
      { visitorId }
    );
    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while scanning the QR code.");
  }
};

export const validateQR = async (qrcode_data, id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/qr/validate?id=${id}`,
      { qrcode_data }
    );

    return response.data.message; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};
