import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Select,
  MenuItem,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
  FormControlLabel,
  List,
  Chip,
  RadioGroup,
  Radio,
  Checkbox,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { filterAttendees } from "../../utils/Filter";
import { Toast, confirmDialog } from "../../utils/Alert";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addAttendee,
  fetchAttendees,
  uploadExcel,
  submitExcel,
  deleteAttendee,
  getAttendeeDetails,
  deleteRep,
  editAttendeeDets,
  sendQR,
} from "../../services/eventsAPI"; //API call
import { addleads } from "../../services/leadsAPI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

// icons
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EventIcon from "@mui/icons-material/Event";
import StarRateIcon from "@mui/icons-material/StarRate";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

// Table columns
const columns = [
  { id: "vip", label: "", minWidth: 20 },
  { id: "status", label: "Status", minWidth: 120 },
  { id: "level_of_interest", label: "Interest", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "contact", label: "Contact", minWidth: 130 },
  { id: "company", label: "Company/Organization", minWidth: 225 },
];

function EventDetails({ isMarketing }) {
  const navigate = useNavigate();
  const { id, event } = useParams(); // Get the ID from the URL (event ID)
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");

  // Add Attendee Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Attendee data
  const [attendeeData, setAttendeeData] = useState({
    // state for attendee data
    name: "",
    vip: false,
    walk_in: false,
    email: "",
    contact_number: "",
    status: "",
    level_of_interest: "",
    job_title: "",
    company_name: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
    notes: "",
    representatives: [],
  });

  //closes drawer and clear attendee data
  const closeDrawer = () => {
    setOpen(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
    setIsUpload(false);
    clearExcel();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAttendeeData((prevState) => ({
      ...prevState,
      [name]: name === "vip" ? value === "true" : value, // Convert "true"/"false" to boolean for vip
    }));
  };

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // Fetch Attendee
  const { isLoading: loadingq, data: attendees } = useQuery({
    queryKey: ["attendees"], // Query key for caching and invalidation
    queryFn: () => fetchAttendees(id), // Function to fetch data
  });

  // Adding Attendee
  const addmutation = useMutation({
    mutationFn: (formData) => addAttendee(id, formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee added!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleSubmitAttendee = () => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === "" ||
      attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    addmutation.mutate(attendeeData);
  };

  // Adding more representative
  const handleAddRepresentative = () => {
    // Add a new representative group
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: [
        ...prevState.representatives,
        { name: "", email: "", contact_number: "", company_name: "", role: "" },
      ],
    }));
  };

  const handleRepresentativeChange = (index, field, value) => {
    // Update the specific representative's field
    const updatedReps = [...attendeeData.representatives];
    updatedReps[index][field] = value;
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: updatedReps,
    }));
  };

  // Handle change page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Section for uploading excel file
  const [isUpload, setIsUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [dbFields, setDbFields] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload file and get headers
  const uploadFile = async () => {
    if (!file) {
      notification("error", "Please select a file first.");
      return;
    }
    try {
      const response = await uploadExcel(id, file);
      setExcelHeaders(response.data.excel_headers);
      setDbFields(response.data.column_names);
      notification(
        "success",
        "Headers loaded successfully. Match the fields below."
      );
    } catch (error) {
      notification("error", "Error loading headers. Please try again.");
    }
  };

  // Handle field mapping change
  const handleMappingChange = (excelHeader, e) => {
    setFieldMapping((prev) => ({
      ...prev,
      [excelHeader]: e.target.value,
    }));
  };

  // Adding Attendees Request
  const addBulkmutation = useMutation({
    mutationFn: ({ id, file, fieldMapping }) =>
      submitExcel(id, file, fieldMapping),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendees added!");
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  // Submit the mapped fields and data
  const submitData = async () => {
    const requiredFields = [
      "name",
      "email",
      "contact_number",
      "level_of_interest",
    ];
    const unmappedFields = requiredFields.filter(
      (field) => !Object.values(fieldMapping).includes(field)
    );

    if (unmappedFields.length > 0) {
      notification(
        "error",
        `Please map the following fields: ${unmappedFields.join(", ")}`
      );
      return;
    }

    setLoading(true);
    addBulkmutation.mutate({ id, file, fieldMapping });
  };

  const clearExcel = async () => {
    setDbFields([]);
    setExcelHeaders([]);
    setFile(null);
    setFieldMapping({});
  };

  // edit attendee section
  const [attendeeID, setAttendeeID] = useState(); // selected attendee ID

  const editAttendee = async (attendee_ID) => {
    setOpenEdit(true);
    setAttendeeID(attendee_ID);
    const selected_attendee = await getAttendeeDetails(attendee_ID, id);
    setAttendeeData(selected_attendee);
  };

  // Edit Attendee
  const editAttendeemutation = useMutation({
    mutationFn: (attendee_ID) =>
      editAttendeeDets(attendee_ID, id, attendeeData),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee updated!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleEditAttendee = (attendee_ID) => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === "" ||
      attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    editAttendeemutation.mutate(attendee_ID);
  };

  // Add Attendee Drawer
  const [openEdit, setOpenEdit] = React.useState(false);
  //closes drawer and clear attendee data
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
  };

  // Delete Attendee______________________
  const deleteAttendeeMutation = useMutation({
    mutationFn: () => deleteAttendee(attendeeID, id),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee successfully deleted");
    },
  });

  const handleDeleteAttendee = async () => {
    const userConfirmed = await confirmDialog({
      title: "Delete attendee?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteAttendeeMutation.mutate();
    }
  };

  // Delete Representative______________________
  const deleteRepMutation = useMutation({
    mutationFn: ({ visitorID, repID }) => deleteRep(id, visitorID, repID),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Representative successfully deleted");
    },
  });

  const handleDeleteRep = async (visitorID, repID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete representative?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteRepMutation.mutate({ visitorID, repID });
    }
  };

  // Email Dialog_________

  // Filter confirmed attendees once attendees data is fetched
  React.useEffect(() => {
    if (attendees) {
      // Filter attendees with status 'Confirmed'
      const filtered = attendees[0].attendees.filter(
        (attd) => attd.status === "Confirmed"
      );
      setConfirmedAttendees(filtered);
    }
  }, [attendees]);

  const [confirmedAttendees, setConfirmedAttendees] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]); //selected attendees on the autocomplete
  const [finalAttendees, setFinalAttendees] = useState([]); // final attendees to send email (QR)
  const [action, setAction] = useState(""); // "qr" or "custom"
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setAction("");
    setSelectAll(false);
    setSelectedAttendees(null);
    setFinalAttendees([]);
  };

  const handleActionChange = (type) => {
    setAction(type);
  };

  // Autocomplete Section
  const handleSelect = (event, newValue) => {
    const employeeData = newValue.map((emp) => ({
      id: emp._id,
      name: emp.name,
      isRepPresent: emp.representatives.length === 0 ? true : false,
    }));
    setSelectedAttendees(employeeData);
  };

  // Checkbox to select all Confirmed Attendee
  const handleSelectAll = (event) => {
    const newSelectAll = event.target.checked;
    setSelectAll(newSelectAll);

    // Reset selected attendees if "select all" is unchecked
    if (!newSelectAll) {
      setSelectedAttendees(null);
      return;
    }
    const employeeData = confirmedAttendees.map((emp) => ({
      id: emp._id,
      name: emp.name,
      isRepPresent: emp.representatives.length === 0 ? true : false,
    }));

    setSelectedAttendees(employeeData);
  };

  const handleRadioChange = (attd, value) => {
    const updatedFinalAttendees = [...finalAttendees];
    const existingIndex = updatedFinalAttendees.findIndex(
      (attendee) => attendee.id === attd.id
    );

    if (existingIndex !== -1) {
      updatedFinalAttendees[existingIndex] = {
        id: attd.id,
        main: value === "Main Attendee" ? true : false,
      };
    } else {
      updatedFinalAttendees.push({
        id: attd.id,
        main: value === "Main Attendee" ? true : false,
      });
    }

    setFinalAttendees(updatedFinalAttendees);
  };

  const handleConfirmEmail = async () => {
    await sendQR(finalAttendees, id);
    notification("success", "QR Sent Successfully!");
    handleClose();
  };

  const [toLeads, setToLeads] = useState([]);

  // Copy to leads
  const copytoLeads = async () => {
    setToLeads({
      project_name: "Event:" + event,
      company_name: attendeeData.company_name,
      implementing_agency: "",
      company_email: attendeeData.company_email,
      company_contact_number: attendeeData.company_contact_number,
      estimated_value: "10",
      nature_of_business: attendeeData.nature_of_business,
      interaction_logs: [],
      action_items: [],
      status: "Outbound",
      level_of_priority: "",
      product_interested_in: [],
      source: event,
      contact_person: [],
      champion_details: [
        {
          name: attendeeData.visitor_name,
          email: attendeeData.email,
          phone_number: attendeeData.contact_number,
          job_title: attendeeData.job_title,
        },
      ],
    });

    const userConfirmed = await confirmDialog({
      title: "Copy attendee?",
      text: "Details will be copy to Leads",
    });
    if (userConfirmed) {
      copyLeadmutation.mutate();
    }
  };

  // Copy attendeed to Leads
  const copyLeadmutation = useMutation({
    mutationFn: () => addleads(toLeads),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Copied Successfully !");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  // Cleanup logic when exiting
  React.useEffect(() => {
    return () => {
      // Optionally clear cache or perform additional cleanup
      queryClient.removeQueries(["attendees", id]); // Removes this specific query's cache
    };
  }, [id, queryClient]);

  if (loadingq || loadingq) return <div>Loading...</div>; //loading state
  const filteredData = filterAttendees(attendees[0].attendees, searchTerm);
  return (
    <>
      {/* Main */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          {attendees[0].name}
        </Typography>
        <Grid container spacing={2}>
          <Grid size={8}>
            {isMarketing && (
              <Button
                variant="contained"
                startIcon={<PersonAddAltIcon />}
                size="small"
                onClick={toggleDrawer(true)}
              >
                Add Attendee
              </Button>
            )}
          </Grid>
          <Grid
            size={4}
            container
            justifyContent="flex-end"
            alignItems={"center"}
          >
            {isMarketing && (
              <>
                {" "}
                <IconButton
                  title="Scan QR"
                  onClick={() => navigate(`/events/QR/${id}`)}
                >
                  <QrCodeScannerIcon />
                </IconButton>
                <IconButton
                  title="Send Email"
                  onClick={() => handleClickOpen()}
                >
                  <MailOutlineIcon />
                </IconButton>
              </>
            )}
            <TextField
              label="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid size={12} sx={{ mb: 2 }}>
            {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
            <TableContainer sx={{ width: "100%", overflow: "hidden" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    // Display a loading indicator properly wrapped in table elements
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row._id}
                          onClick={() => editAttendee(row._id)}
                          className="table-row"
                        >
                          <TableCell align="left">
                            {row.vip ? <StarRateIcon fontSize="small" /> : ""}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row">
                              <Chip
                                size="small"
                                color={
                                  row.status === "Confirmed"
                                    ? "primary"
                                    : row.status === "No-show"
                                      ? "secondary"
                                      : row.status === "Attended"
                                        ? "success"
                                        : row.status === "Rejected"
                                          ? "error"
                                          : "default" // or any other default color
                                }
                                label={row.status}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {row.level_of_interest}
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">
                            {row.contact_number}
                          </TableCell>
                          <TableCell align="left">{row.company_name}</TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={attendees[0].attendees.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Box>

      {/* Drawer for Adding an Attendee */}
      <Drawer open={open} onClose={() => closeDrawer()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          {isUpload ? (
            <>
              <Grid container justifyContent={"center"} rowSpacing={0.5}>
                <Grid size={12} display="flex" alignItems="center">
                  <EventIcon />
                  <Typography variant="body" sx={{ ml: 1 }}>
                    Excel Bulk Upload
                  </Typography>
                </Grid>

                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  style={{ marginBottom: "16px" }}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={uploadFile}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Upload"}
                </Button>

                {/* Field Matching Section */}
                {excelHeaders.length > 0 && dbFields.length > 0 && (
                  <>
                    <Grid size={12} display="flex" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        Match Fields
                      </Typography>
                    </Grid>
                    {excelHeaders.map((header, index) => (
                      <>
                        <Grid size={12} display="flex">
                          <Typography variant="body2">{header}</Typography>
                        </Grid>

                        <Grid size={12} display="flex" sx={{ mb: 1 }}>
                          <TextField
                            size="small"
                            select
                            label="Select a database field"
                            onChange={(e) => handleMappingChange(header, e)}
                            fullWidth
                            defaultValue=""
                            variant="outlined"
                          >
                            <MenuItem value="" disabled>
                              Select a database field
                            </MenuItem>
                            {dbFields.map((field, idx) => (
                              <MenuItem key={idx} value={field}>
                                {field}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitData}
                      disabled={loading}
                    >
                      {loading ? "Uploading..." : "Submit Data"}
                    </Button>
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid size={10} display="flex" alignItems="center">
                  <EventIcon />
                  <Typography variant="body" sx={{ ml: 1 }}>
                    Add Attendee
                  </Typography>
                </Grid>
                <Grid size={2} display="flex" justifyContent="flex-end">
                  <IconButton
                    title="Import Excel"
                    onClick={() => setIsUpload(true)}
                  >
                    <PostAddIcon />
                  </IconButton>
                </Grid>
                <Grid size={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid size={10}>
                  <Typography variant="body2">Name *</Typography>
                </Grid>
                <Grid size={2}>
                  <Typography variant="body2">VIP?</Typography>
                </Grid>
                <Grid size={9}>
                  <TextField
                    fullWidth
                    size="small"
                    name="name"
                    value={attendeeData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={3}>
                  <Select
                    size="small"
                    fullWidth
                    name="vip"
                    value={String(attendeeData.vip)} // Convert boolean to string for the Select component
                    onChange={handleChange}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Status *</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Level of Interest *</Typography>
                </Grid>

                <Grid size={6}>
                  <Select
                    size="small"
                    fullWidth
                    name="status"
                    value={attendeeData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Confirmed">Confirmed</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                    <MenuItem value="Attended">Attended</MenuItem>
                    <MenuItem value="No-show">No-show</MenuItem>
                  </Select>
                </Grid>

                <Grid size={6}>
                  <Select
                    size="small"
                    fullWidth
                    name="level_of_interest"
                    value={attendeeData.level_of_interest}
                    onChange={handleChange}
                  >
                    <MenuItem value="Potential">Potential</MenuItem>
                    <MenuItem value="Casual">Casual</MenuItem>
                  </Select>
                </Grid>

                <Grid size={12}>
                  <Typography variant="body2">Role / Position</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    name="job_title"
                    value={attendeeData.job_title}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Email *</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Phone *</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="email"
                    value={attendeeData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="contact_number"
                    type="number"
                    value={attendeeData.contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">
                    Company / Organization
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    name="company_name"
                    value={attendeeData.company_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">Industry</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    name="nature_of_business"
                    value={attendeeData.nature_of_business}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Company / Org. Email</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Company / Org. Phone</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="company_email"
                    value={attendeeData.company_email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="company_contact_number"
                    value={attendeeData.company_contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">Note</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    name="notes"
                    value={attendeeData.notes}
                    onChange={handleChange}
                  />
                </Grid>

                {attendeeData?.representatives?.map((rep, index) => (
                  <>
                    <Grid item size={12} sx={{ mt: 2, mb: 2 }}>
                      <Divider />
                    </Grid>
                    <Grid item size={12}>
                      <Typography variant="body2">
                        Representative Name
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.name}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item size={12}>
                      <Typography variant="body2">Role / Position</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.role}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "role",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item size={6}>
                      <Typography variant="body2">Email</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.email}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "email",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item size={6}>
                      <Typography variant="body2">Phone Number</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.contact_number}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "contact_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </>
                ))}
                <Grid size={2}>
                  <IconButton
                    title="Add Representative"
                    onClick={handleAddRepresentative}
                  >
                    <PersonAddAltIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2">
                    Click to add Representative/s.
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={closeDrawer}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSubmitAttendee}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Drawer>

      {/* Drawer for Editing an Attendee */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <EventIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Edit Attendee
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
            <Grid size={10}>
              <Typography variant="body2">Name *</Typography>
            </Grid>
            <Grid size={2}>
              <Typography variant="body2">VIP?</Typography>
            </Grid>
            <Grid size={9}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="visitor_name"
                value={attendeeData.visitor_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={3}>
              <Select
                size="small"
                disabled={!isMarketing}
                fullWidth
                name="vip"
                value={String(attendeeData.vip)} // Convert boolean to string for the Select component
                onChange={handleChange}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Status *</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Level of Interest *</Typography>
            </Grid>

            <Grid size={6}>
              <Select
                size="small"
                disabled={!isMarketing}
                fullWidth
                name="status"
                value={attendeeData.status}
                onChange={handleChange}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Confirmed">Confirmed</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Attended">Attended</MenuItem>
                <MenuItem value="No-show">No-show</MenuItem>
              </Select>
            </Grid>

            <Grid size={6}>
              <Select
                size="small"
                disabled={!isMarketing}
                fullWidth
                name="level_of_interest"
                value={attendeeData.level_of_interest}
                onChange={handleChange}
              >
                <MenuItem value="Potential">Potential</MenuItem>
                <MenuItem value="Casual">Casual</MenuItem>
              </Select>
            </Grid>

            <Grid size={12}>
              <Typography variant="body2">Role / Position</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="job_title"
                value={attendeeData.job_title}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Email *</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Phone *</Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="email"
                value={attendeeData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="contact_number"
                type="number"
                value={attendeeData.contact_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Company / Organization</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="company_name"
                value={attendeeData.company_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Industry</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="nature_of_business"
                value={attendeeData.nature_of_business}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Company / Org. Email</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Company / Org. Phone</Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="company_email"
                value={attendeeData.company_email}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="company_contact_number"
                type="number"
                value={attendeeData.company_contact_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Note</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                multiline
                rows={3}
                name="notes"
                value={attendeeData.notes}
                onChange={handleChange}
              />
            </Grid>

            {attendeeData.representatives.map((rep, index) => (
              <>
                <Grid item size={12} sx={{ mt: 2, mb: 2 }}>
                  <Divider />
                </Grid>
                <Grid item size={12}>
                  <Typography variant="body2">
                    Representative Name {index + 1}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!isMarketing}
                    value={rep.name}
                    onChange={(e) =>
                      handleRepresentativeChange(index, "name", e.target.value)
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              title="Delete Representative"
                              onClick={() =>
                                handleDeleteRep(
                                  attendeeData.attendee_id,
                                  rep._id
                                )
                              }
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>
                <Grid item size={12}>
                  <Typography variant="body2">Role / Position</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!isMarketing}
                    value={rep.role}
                    onChange={(e) =>
                      handleRepresentativeChange(index, "role", e.target.value)
                    }
                  />
                </Grid>
                <Grid item size={6}>
                  <Typography variant="body2">Email</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!isMarketing}
                    value={rep.email}
                    onChange={(e) =>
                      handleRepresentativeChange(index, "email", e.target.value)
                    }
                  />
                </Grid>
                <Grid item size={6}>
                  <Typography variant="body2">Phone Number</Typography>
                  <TextField
                    fullWidth
                    disabled={!isMarketing}
                    size="small"
                    value={rep.contact_number}
                    onChange={(e) =>
                      handleRepresentativeChange(
                        index,
                        "contact_number",
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </>
            ))}
            <Grid item size={12}>
              <Divider />
            </Grid>
            {isMarketing && (
              <>
                <Grid size={2}>
                  <IconButton
                    title="Add Representative"
                    onClick={handleAddRepresentative}
                  >
                    <PersonAddAltIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2">
                    Click to add Representative/s.
                  </Typography>
                </Grid>

                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <IconButton
                    title="Delete"
                    onClick={() => handleDeleteAttendee()}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    size="small"
                    color="outboundColor"
                    onClick={copytoLeads}
                    sx={{ mr: 0.5 }}
                  >
                    Copy to Leads
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ mr: 0.5 }}
                    onClick={closeDrawerEdit}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mr: 0.5 }}
                    onClick={() => handleEditAttendee(attendeeData.attendee_id)}
                  >
                    Update
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Drawer>

      {/* Dialog for sending email */}
      <Dialog open={openDialog} fullWidth maxWidth={"lg"}>
        <DialogTitle>{"Send Email"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent={"center"} sx={{ mb: 2 }}>
            <Grid size={6} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={action === "qr"}
                    onChange={() => handleActionChange("qr")}
                  />
                }
                label="QR Code"
              />
            </Grid>
            <Grid size={6} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={action === "custom"}
                    onChange={() => handleActionChange("custom")}
                  />
                }
                label="Custom Email"
              />
            </Grid>
          </Grid>
          {action === "qr" ? (
            <>
              <Autocomplete
                disabled={selectAll}
                multiple
                options={confirmedAttendees}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={handleSelect}
                renderInput={(params) => (
                  <TextField {...params} label="Attendee" placeholder="Name" />
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll} // Corrected to handleSelectAll
                  />
                }
                label={
                  <Typography variant="overline">
                    Select All (Confirmed) Attendee
                  </Typography>
                }
              />
            </>
          ) : action === "custom" ? (
            <>
              {" "}
              <ReactQuill />
            </>
          ) : (
            "Select action"
          )}
          {selectedAttendees ? (
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{ mb: 2 }}
            >
              <Grid size={12}>
                <Divider />
              </Grid>
              {selectedAttendees.map((attd) => (
                <Paper key={attd.id} sx={{ p: 2 }}>
                  <Typography>{attd.name}</Typography>
                  <RadioGroup
                    row
                    onChange={(event) =>
                      handleRadioChange(attd, event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Main Attendee"
                      control={<Radio />}
                      label="Main Attendee"
                    />
                    <FormControlLabel
                      value="Representative"
                      control={<Radio disabled={attd.isRepPresent} />}
                      label="Representative"
                    />
                  </RadioGroup>
                </Paper>
              ))}
            </Grid>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmEmail} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EventDetails;
