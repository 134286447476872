import React, { useContext } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { PageContainer } from "@toolpad/core/PageContainer";
import {
  Stack,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  Account,
  AccountPreview,
  AccountPopoverFooter,
  SignOutButton,
} from "@toolpad/core/Account";
import SettingsIcon from "@mui/icons-material/Settings";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

// Icons
import EventIcon from "@mui/icons-material/Event";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

// Routes
import Dashboard from "../Dashboard/Dashboard";
import Events from "../Events/Events";
import Leads from "..//Leads/Leads";
import EventDetails from "../Events/Event-Details";
import EventVIP from "../Events/VIP";
import QR from "../Events/QR";

const NAVIGATION = [
  {
    kind: "header",
    title: "Main",
  },
  {
    segment: "leads",
    title: "Leads",
    icon: <AccountTreeIcon />,
  },
  {
    segment: "events",
    title: "Events",
    icon: <EventIcon />,
    children: [
      {
        segment: "dashboard",
        title: "Dashboard",
        icon: <EventNoteIcon />,
      },
      {
        segment: "vip",
        title: "VIPs",
        icon: <PersonPinIcon />,
      },
    ],
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Analytics",
  },
  {
    segment: "reports",
    title: "Reports",
    icon: <BarChartIcon />,
    children: [
      {
        segment: "sales",
        title: "Sales",
        icon: <DescriptionIcon />,
      },
      {
        segment: "traffic",
        title: "Traffic",
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    segment: "sales",
    title: "Sales",
    icon: <LayersIcon />,
  },
];

// Account sidebar preview
function AccountSidebarPreview(props) {
  const { handleClick, open, mini } = props;
  return (
    <Stack direction="column" p={0} overflow="hidden">
      <Divider />
      <AccountPreview
        variant={mini ? "condensed" : "expanded"}
        handleClick={handleClick}
        open={open}
      />
    </Stack>
  );
}

AccountSidebarPreview.propTypes = {
  /**
   * The handler used when the preview is expanded
   */
  handleClick: PropTypes.func,
  mini: PropTypes.bool.isRequired,
  /**
   * The state of the Account popover
   * @default false
   */
  open: PropTypes.bool,
};

// Pop over layout for account, can add additional layout such list (logout, account settings)
function SidebarFooterAccountPopover() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const onSignout = async () => {
    await logout(); // Perform signout action
    navigate("/"); // Redirect to login page after sign out
  };
  return (
    <Stack direction="column">
      <MenuList>
        <MenuItem
          component="button"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
            columnGap: 2,
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Account Information</ListItemText>
        </MenuItem>
      </MenuList>
      <Divider />
      <AccountPopoverFooter>
        <SignOutButton onClick={onSignout} />
      </AccountPopoverFooter>
    </Stack>
  );
}

const createPreviewComponent = (mini) => {
  function PreviewComponent(props) {
    return <AccountSidebarPreview {...props} mini={mini} />;
  }
  return PreviewComponent;
};

function SidebarFooterAccount({ mini }) {
  const PreviewComponent = React.useMemo(
    () => createPreviewComponent(mini),
    [mini]
  );
  return (
    <Account
      slots={{
        preview: PreviewComponent,
        popoverContent: SidebarFooterAccountPopover,
      }}
      slotProps={{
        popover: {
          transformOrigin: { horizontal: "left", vertical: "bottom" },
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          disableAutoFocus: true,
          slotProps: {
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: (theme) =>
                  `drop-shadow(0px 2px 8px ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.10)" : "rgba(0,0,0,0.32)"})`,
                mt: 1,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: 10,
                  left: 0,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translate(-50%, -50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          },
        },
      }}
    />
  );
}

SidebarFooterAccount.propTypes = {
  mini: PropTypes.bool.isRequired,
};

const renderPage = (pathname, isMarketing, user) => {
  if (pathname.startsWith("/event/")) {
    const eventId = pathname.split("/event/")[1];
    return <EventDetails eventId={eventId} isMarketing={isMarketing} />;
  }

  if (pathname.startsWith("/events/QR/")) {
    const eventId = pathname.split("/event/QR/")[1];
    return <QR eventId={eventId} isMarketing={isMarketing} />;
  }

  switch (pathname) {
    case "/dashboard":
      return <Dashboard isMarketing={isMarketing} user={user}/>;
    case "/events":
      return <Events isMarketing={isMarketing} />;
    case "/events/dashboard":
      return <Events isMarketing={isMarketing} />;
    case "/events/vip":
      return <EventVIP isMarketing={isMarketing} />;
    case "/leads":
      return <Leads isMarketing={isMarketing} />;
    case "/reports/sales":
      return <Leads isMarketing={isMarketing} />;
    default:
      return <Dashboard isMarketing={isMarketing} />;
  }
};

export default function Main() {
  const theme = useTheme(); 
  const { user, isMarketing } = useContext(AuthContext);
  const navigate = useNavigate();
  const currentUser = {
    user: {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      image: `https://hr.geospectrum.com.ph/api/profile/${user.photo_path}`,
    },
  };

  const [session, setSession] = React.useState(currentUser);
  const authentication = React.useMemo(
    () => ({
      signIn: () => setSession(currentUser),
      signOut: () => setSession(null),
    }),
    []
  );

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={{
        pathname: window.location.pathname,
        navigate,
        searchParams: new URLSearchParams(window.location.search),
      }}
      theme={theme}
      authentication={authentication}
      session={session}
      branding={{
        logo: <img src="/GMSLOGO.png" alt="GMS logo" />,
        title: "Marketing Portal",
      }}
    >
      <DashboardLayout
        slots={{
          toolbarAccount: () => null,
          sidebarFooter: SidebarFooterAccount,
        }}
      >
        <PageContainer title="" breadcrumbs={[]}>
          {renderPage(window.location.pathname, isMarketing, user)}
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}
