import axios from "axios";

// Leads
export const addleads = async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/leads/add`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data.message; // Return the response data for further use
};

export const fetchLeads = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/leads/all`);
    return response.data.allLeads;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching leads. Please try again later."
    );
  }
};

export const getLeadDetails = async (leadId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/details?id=${leadId}`
    );
    return response.data.details;
  } catch (error) {
    console.error("Error fetching lead details:", error.message);
    alert(error.message);
  }
};

export const updateLead = async (id, data) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/details?id=${id}`,
    data
  );
  return response; // Return the response data for further use
};

// Champion
export const updateChampion = async (leadID, championid, data) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/champion/update?id=${leadID}&championId=${championid}`,
    data
  );
  return response;
};

// Contact Persom
export const updateContact = async (leadID, contactid, data) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/contact-person/update?id=${leadID}&contactId=${contactid}`,
    data
  );
  return response;
};

// Interaction log
export const updateLog = async (leadID, logId, data) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/logs/update?id=${leadID}&logId=${logId}`,
    data
  );
  return response; // Return the response data for further use
};

// Employee
export const fetchEmployees = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/get-employees`
    );
    return response.data.coworkers;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching employees. Please try again later."
    );
  }
};

// Action Item
export const addItem = async (data, leadID) => {
  // console.log(data, leadID)
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/action-items/add?id=${leadID}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.message; // Return the response data for further use
};

export const updateActionItem = async (leadID, itemID, data) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/action-items/update?id=${leadID}&itemId=${itemID}`,
    data
  );
  return response; // Return the response data for further use
};

export const fetchActionItem = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/leads/pending-actions`
    );
    return response.data.action_items;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching employees. Please try again later."
    );
  }
};