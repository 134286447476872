import moment from "moment/moment";

export const filterEvents = (events, searchTerm) => {
  return events
    ? events.filter((item) => {
        const itemName =
          item.name +
            item.description +
            item.location +
            moment(item.start_date).format("LL") +
            moment(item.end_date).format("LL") || ""; // Handle potential undefined
        const searchTermLower = searchTerm.toLowerCase();
        return itemName.toLowerCase().includes(searchTermLower);
      })
    : [];
};

export const filterAttendees = (attendees, searchTerm) => {
  return attendees
    ? attendees.filter((item) => {
        const itemName =
          item.name +
            item.email +
            item.status +
            item.company_name +
            item.contact_number +
            item.level_of_interest || ""; // Handle potential undefined
        const searchTermLower = searchTerm.toLowerCase();
        return itemName.toLowerCase().includes(searchTermLower);
      })
    : [];
};
