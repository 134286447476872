import React, { useState, useRef, useContext } from "react";
import { AuthContext } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

// Signin component for the login form
export function Signin() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  // Initializing states for form fields, error, loading, and success messages
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
  });

  // Destructuring values from the state
  const { email, password, error } = values;

  // Handles changes in the input fields
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  // Submits the form data to the backend for user authentication
  const SubmitForm = async (event) => {
    event.preventDefault();
    setValues({ ...values, success: false, loading: true });

    const loginResult = await login({ email, password });
    navigate("/marketing");
    if ("err" in loginResult) {
      setValues({
        ...values,
        error: loginResult.err,
        loading: false,
        success: false,
      });
    } else {
      setValues({ success: true });
    }
  };

  // Displays error message if there's any
  const errorMessage = () => {
    return (
      <div
        className="error-message"
        style={{ display: error ? "" : "none", color: "red" }}
      >
        {error}
      </div>
    );
  };

  const [showForm, setShowForm] = useState(false);
  const formContainerRef = useRef(null);

  // Show Password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <div>
        <header className="header">
          <nav className="nav">
            <a href="#" className="nav_logo">
              Marketing Portal
            </a>
            <ul className="nav_items">
              <li className="nav_item">
                <a href="https://geospectrum.com.ph/home" className="nav_link">
                  Home
                </a>
                <a href="https://geospectrum.com.ph/about" className="nav_link">
                  About Us
                </a>
                <a
                  href="https://geospectrum.com.ph/products"
                  className="nav_link"
                >
                  Products
                </a>
                <a
                  href="https://geospectrum.com.ph/contact"
                  className="nav_link"
                >
                  Contact
                </a>
              </li>
            </ul>
            <button className="button" onClick={() => setShowForm(true)}>
              Login
            </button>
          </nav>
        </header>

        <section className={`home ${showForm ? "show" : ""}`}>
          <div className={`form_container`} ref={formContainerRef}>
            <i className="form_close" onClick={() => setShowForm(false)}>
              <CloseIcon fontSize="small" />
            </i>

            {/* Login Form */}
            <div className="form login_form" onSubmit={SubmitForm}>
              <form action="#">
                <h2>Login</h2>

                <TextField
                  fullWidth
                  label="Email"
                  value={email}
                  onChange={handleChange("email")}
                  type="text"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon sx={{ color: "#a3a3a3" }} />
                      </InputAdornment>
                    ),
                  }}
                  id="email"
                  sx={{ mb: 2, mt: 2 }}
                  required
                  autoComplete="off"
                />

                <TextField
                  fullWidth
                  variant="standard"
                  label="Password"
                  value={password}
                  onChange={handleChange("password")}
                  type={showPassword ? "Text" : "Password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          disableRipple
                        >
                          {showPassword ? (
                            <VisibilityIcon
                              sx={{ color: "#a3a3a3" }}
                              fontSize="small"
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{ color: "#a3a3a3" }}
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon sx={{ color: "#a3a3a3" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="input"
                  id="pass"
                  required
                />

                <div className="option_field">
                  {errorMessage()}
                  <a
                    href="https://hr.geospectrum.com.ph/forgot-password"
                    className="forgot_pw"
                  >
                    Forgot password?
                  </a>
                </div>

                <button className="button" type="submit">
                  Login Now
                </button>

                <div className="login_signup">
                  Don't have an account?{" "}
                  <a href="https://hr.geospectrum.com.ph/signup">Signup here</a>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Signin;
