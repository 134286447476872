import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const AuthRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);

  //manage the component rendering based on the authentication status check
  if (loading) {
    return <div>Loading...</div>; // Or some loading indicator
  }
// If a user is authenticated, show the element; otherwise, redirect to the login page
  return user ? element : <Navigate to="/" />;
};

AuthRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default AuthRoute;
