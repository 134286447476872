import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import PropTypes from "prop-types";

const PublicRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);

  //manage the component rendering based on the authentication status check
  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? <Navigate to="/dashboard" /> : element;
};

PublicRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default PublicRoute;
